import React from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';

function Offer() {
    return (
        <>
            <section className="container-fluid mt-5 mb-5">
                <Container>
                    <Row>
                        <Col md={6} lg={12}>
                            <h2
                                style={{
                                    display: 'inline-block',
                                    borderBottom: '7px solid black',
                                    fontWeight: 'bold',
                                }}
                                className="mb-3">
                                Ofertă Securitate şi Sănătate în Muncă
                            </h2>

                            <p>
                                ECOMONDO SSM ofera in domeniul securitatii si
                                sanatatii in munca consultanta de specialitate
                                pe probleme de securitatea si sanatatea
                                lucratorilor in conformitate cu normele
                                metodologice de aplicare a prevederilor Legii
                                securitatii si sanatatii in munca 319/2006.
                            </p>
                            <ul>
                                <li className="mb-3">
                                    Identificarea pericolelor si evaluarea
                                    riscurilor pentru fiecare loc de munca.
                                </li>
                                <li className="mb-3">
                                    Elaborarea si actualizarea planului de
                                    prevenire si protectie
                                </li>
                                <li className="mb-3">
                                    Elaborarea de instrucţiuni proprii pentru
                                    completarea şi/sau aplicarea reglementărilor
                                    de securitate şi sănătate în munca, ţinând
                                    seama de particularităţile activităţilor şi
                                    ale unităţii, precum şi ale locurilor de
                                    munca/ posturilor de lucru
                                </li>
                                <li className="mb-3">
                                    Intocmirea unui necesar de documentaţii cu
                                    caracter tehnic de informare şi instruire a
                                    lucrătorilor în domeniul securităţii şi
                                    sănătăţii în munca
                                </li>
                                <li className="mb-3">
                                    Elaborarea programului de instruire-testare
                                    la nivelul intreprinderii si/sau unitatii
                                </li>
                                <li className="mb-3">
                                    Evaluarea riscurilor si documentatia pentru
                                    securitatea si sanatatea in munca sunt
                                    obligatoriu sa fie realizate si sa se afle
                                    in posesia angajatorului (conform Art. 12
                                    Legea 319/2006).
                                </li>
                            </ul>
                        </Col>
                        <Col md={6} lg={12} className="mt-5">
                            <div>
                                <h3
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        borderBottom: '4px solid black',
                                    }}
                                    className="mb-1 mb-lg-3">
                                    Evaluarea riscurilor
                                </h3>
                                <Badge
                                    pill
                                    bg="success"
                                    className="mx-3 p-2 shadow">
                                    350 RON / post de munca
                                </Badge>

                                <ul>
                                    <li className="mb-3">
                                        Evaluarea riscurilor şi documentaţia
                                        pentru securitatea şi sănătatea în muncă
                                        sunt obligatoriu să fie realizate şi să
                                        se afle în posesia angajatorului
                                        (conform Art. 12 Legea 319/2006).
                                    </li>
                                    <li className="mb-3">
                                        Identificarea pericolelor şi evaluarea
                                        riscurilor pentru fiecare loc de muncă;
                                    </li>
                                    <li className="mb-3">
                                        Elaborarea şi actualizarea planului de
                                        prevenire şi protecţie.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} lg={12} className="mt-5">
                            <div>
                                <h3
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        borderBottom: '4px solid black',
                                    }}
                                    className="mb-1 mb-lg-3">
                                    Întocmirea dosarului
                                </h3>
                                <Badge
                                    pill
                                    bg="success"
                                    className="mx-3 p-2 shadow">
                                    1000+ RON / dosar SSM / PSI
                                </Badge>

                                <ul>
                                    <li className="mb-3">
                                        Întocmirea dosarului cu documentele
                                        cerute de Legea 319/2006 şi de normele
                                        metodologice de aplicare a Legii
                                        319/2006
                                    </li>

                                    <li className="mb-3">
                                        Instrucţiuni proprii de securitate şi
                                        sănătate pentru fiecare loc de muncă
                                    </li>
                                    <li className="mb-3">
                                        Tematica pentru toate fazele de
                                        instruire{' '}
                                    </li>
                                    <li className="mb-3">
                                        Instruirea introductiv generală de
                                        securitatea muncii, precum şi testarea
                                        cunosţintelor, completarea fişelor
                                        individuale de securitatea muncii;
                                    </li>
                                    <li className="mb-3">
                                        Instruirea conducătorilor de locurilor
                                        de muncă privin organizarea activităţii
                                        de securitate şi sănătate în muncă la
                                        nivel de societate;{' '}
                                    </li>
                                    <li className="mb-3">
                                        Stabilirea zonelor care necesită
                                        semnalizare de securitate şi sănătate în
                                        muncă, stabilirea tipului de semnalizare
                                        necesar şi amplasarea conform
                                        prevederilor HG 971/2006 privind
                                        cerinţele minime pentru semnalizarea de
                                        securitate şi/sau sănătate la locul de
                                        muncă;
                                    </li>
                                    <li className="mb-3">
                                        Evidenţa meseriilor şi a profesiilor
                                        prevăzute de legislaţia specifică,
                                        pentru care este necesară autorizarea
                                        exercitării lor;
                                    </li>
                                    <li className="mb-3">
                                        Evidenţa echipamentelor de muncă şi
                                        urmărirea ca verificările periodice şi
                                        dacă este cazul, încercările periodice
                                        ale echipamentelor de muncă să fie
                                        efectuate de persoane competente,
                                        conform prevederilor din HG 1146/2006
                                        privind cerinţele minime de securitate
                                        şi sănătate pentru utilizarea în muncă
                                        de către lucrători a echipamentelor de
                                        muncă;
                                    </li>
                                    <li className="mb-3">
                                        Identificarea echipamentelor individuale
                                        de protecţie necesare pentru posturile
                                        de lucru din întreprindere şi întocmirea
                                        necesarului de dotare a lucrătorilor cu
                                        echipament individual de protecţie,
                                        conform prevederilor HG 1048/2006
                                        privind cerinţele minime de securitate
                                        şi sănătate pentru utilizarea de către
                                        lucrători a echipamentelor individuale
                                        de protecţie la locul de munca;
                                    </li>
                                    <li className="mb-3">
                                        Elaborarea programului de
                                        instruire-testare la nivelul
                                        intreprinderii şi/sau unităţii.{' '}
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={6} lg={12} className="mt-5">
                            <div>
                                <h3
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        borderBottom: '4px solid black',
                                    }}
                                    className="mb-1 mb-lg-3">
                                    Asistenţă lunară/trimestrială
                                </h3>
                                <Badge
                                    pill
                                    bg="success"
                                    className="mx-3 p-2 shadow text-wrap"
                                    style={{
                                        whiteSpace: 'normal',
                                        lineHeight: '1rem',
                                    }}>
                                    <span className="text-break">
                                        Pretul este in functie de domeniul de
                                        activitate si de numarul de angajati
                                    </span>
                                </Badge>

                                <ul>
                                    <li className="mb-3">
                                        Participarea efectivă la verificarea
                                        însuşirii şi a modului de aplicare a
                                        măsurilor propuse în planul de prevenire
                                    </li>
                                    <li className="mb-3">
                                        Fişele individuale de instruire privind
                                        securitatea şi sănătatea în muncă
                                    </li>
                                    <li className="mb-3">
                                        Fişele individuale de instruire în
                                        domeniul situaţiilor de urgenţă ( P.S.I.
                                        + P.C.)
                                    </li>
                                    <li className="mb-3">
                                        Întocmirea rapoartelor şi listelor
                                        prevăzute în Hotărârile Guvernului
                                    </li>
                                    <li className="mb-3">
                                        Realizarea măsurilor dispuse de către
                                        inspectorii de muncă cu prilejul
                                        vizitelor de control.
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={6} lg={12} className="mt-5">
                            <div>
                                <h3
                                    style={{
                                        // display: 'inline-block',
                                        fontWeight: 'bold',
                                        // borderBottom: '4px solid black',
                                    }}
                                    className="text-center">
                                    Ofertă servicii medicale Medicina Muncii
                                </h3>

                                <hr
                                    className="mx-auto"
                                    style={{
                                        height: '7px',
                                        width: '10%',
                                        border: 'none',
                                        backgroundColor: '#000',
                                    }}
                                />

                                <p>
                                    Conform HG 1169/2011 Cabinetul medical de
                                    Medicina Muncii Iri Consult Med va ofera
                                    urmatoarele pachete pentru eliberarea
                                    fiselor de aptitudine :
                                </p>
                                <ul>
                                    <li>
                                        Pachet activitate de birou
                                        <Badge
                                            pill
                                            bg="success"
                                            className="mx-2 shadow">
                                            55 ron / an
                                        </Badge>
                                    </li>
                                    <li>
                                        Pachet AUTO/manevrare utilaje
                                        intrauzinale.
                                        <Badge
                                            pill
                                            bg="success"
                                            className="mx-2 shadow">
                                            60 ron / an
                                        </Badge>
                                    </li>
                                    <li>
                                        Pachet sector alimentar.
                                        <Badge
                                            pill
                                            bg="success"
                                            className="mx-2 shadow">
                                            55 ron / semestrial
                                        </Badge>
                                    </li>
                                    <li>
                                        Pachet lucru in ture de noapte.
                                        <Badge
                                            pill
                                            bg="success"
                                            className="mx-2 shadow">
                                            55 ron
                                        </Badge>
                                    </li>
                                    <li>
                                        Pachet lucru la inaltime.
                                        <Badge
                                            pill
                                            bg="success"
                                            className="mx-2 shadow">
                                            75 ron / an
                                        </Badge>
                                    </li>
                                </ul>
                                <p>
                                    *Angajatii care conduc masina institutiei
                                    vor efectua de asemenea o testare
                                    psihologica in urma caruia vor primi avizul
                                    psihologic valabil pentru o perioada de 1
                                    an.
                                </p>
                                <p>
                                    La cerere putem personaliza orice tip de
                                    pachet solicitat de investigatii medical
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Offer;
