import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function EmergencySituations() {
    const documentListPSI = [
        { title: 'Norme generale de PSI' },
        { title: 'Organizarea activitatii de PSI' },
    ];
    const documentListPC = [
        { title: 'Cum sa te protejezi in caz de situatii de urgenta' },
        { title: 'OMAI 163/ 2007' },
        {
            title: 'HOTARARE nr. 259 din 31 martie 2005 privind infiintarea si stabilirea atributiilor Centrului National pentru Securitate la Incendiu si Protectie Civila',
        },
        { title: 'LEGE nr. 481 din 8 noiembrie 2004 privind protectia civila' },
        {
            title: 'ORDIN Nr. 1474 din 12 octombrie 2006 pentru aprobarea Regulamentului de planificare, organizare, pregátire si destásurare a activitatii de prevenire a situatiilor de urgenta',
        },
    ];

    return (
        <>
            <section className="container-fluid mt-5 mb-5">
                <Container>
                    <Row>
                        <Col md={6} lg={12} className="mt-5">
                            <div>
                                <h3
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        borderBottom: '4px solid black',
                                    }}
                                    className="mb-3">
                                    P.S.I. (PREVENIREA SI STINGEREA INCENDIILOR)
                                </h3>

                                <ul>
                                    <li className="mb-3">
                                        Intocmeste actele de autoritate emise de
                                        administratorul societatii(conducatorul
                                        institutiei) privind apararea impotriva
                                        incendiilor
                                    </li>
                                    <li>
                                        Intocmeste planurile de protectie
                                        specifice situatiilor de urgenta
                                    </li>
                                    <li className="mb-3">
                                        Efectueaza instructajul introductiv
                                        general in domeniul situatiilor de
                                        urgenta pentru angajatii beneficiarului
                                        la sediul societatii(institutiei),
                                        respectiv la cabinetul de instruire in
                                        domeniul situatiilor de urgenta al S.C.
                                        Ecomondo SSM
                                    </li>
                                    <li className="mb-3">
                                        Intocmeste instructiunile de aparare
                                        impotriva incendiilor
                                    </li>
                                    <li className="mb-3">
                                        Intocmeste tematica anuala si graficul
                                        de instruire – testare a lucratorilor in
                                        domeniul situatiilor de urgenta
                                    </li>
                                    <li className="mb-3">
                                        Asigura consultanta, informarea si
                                        indrumarea privind asigurarea cerintelor
                                        minime de semnalizare in domeniul
                                        situatiilor de urgenta conform H.G.
                                        971/2006 si dotarea cu mijloace tehnice
                                        de stingere a incendiilor
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} lg={12} className="mt-5 mb-5">
                            <div>
                                <h3
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                        borderBottom: '4px solid black',
                                    }}
                                    className="mb-3">
                                    P.C. (PROTECTIE CIVILA)
                                </h3>
                                <ul>
                                    <li className="mb-3">
                                        Elaborarea si implementarea planurilor
                                        de protectie civila la nivel national si
                                        local
                                    </li>
                                    <li className="mb-3">
                                        Identificarea si evaluarea riscurilor de
                                        dezastre si elaborarea masurilor de
                                        prevenire si protectie corespunzatoare
                                    </li>
                                    <li className="mb-3">
                                        Asigurarea pregatirii corespunzatoare a
                                        fortelor si mijloacelor de interventie
                                        in cazul situatiilor de urgenta
                                    </li>
                                    <li className="mb-3">
                                        Coordonarea interventiei in cazul
                                        situatiilor de urgenta, inclusiv
                                        gestionarea crizelor si a masurilor de
                                        salvare si protectie a populatiei si a
                                        proprietatii
                                    </li>
                                    <li className="mb-3">
                                        Educatia publicului cu privire la
                                        riscurile si masurile de precautie in
                                        cazul situatiilor de urgenta
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default EmergencySituations;
