import React from 'react';
import './Footer.scss';

function Footer() {
    return (
        <footer className="bg-dark text-white mt-auto">
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-6 col-lg-4 about-us">
                        <h5 style={{ color: '#0acf83' }}>Despre noi</h5>
                        <p className="fs-7">
                            ECOMONDO SSM ofera solutii complete si personalizate
                            pentru intreprinderi care doresc sa implementeze si
                            sa mentina standarde ridicate de securitate si
                            sanatate in munca. Cu o echipa de experti calificati
                            si experienta vasta in domeniu, suntem dedicati sa
                            oferim servicii de calitate si solutii eficiente
                            pentru a asigura un mediu de lucru sigur si sanatos
                            pentru angajatii dvs.
                        </p>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <h5 style={{ color: '#0acf83' }}>Contactează-ne</h5>
                        <ul className="list-unstyled footer-adress">
                            <li>
                                <a
                                    href="https://goo.gl/maps/SUv566XPtjdvmNq56"
                                    target="_blank">
                                    Sos Mihai Bravu 491-495, sector 3,
                                    <br />
                                    Bucuresti, 030331
                                </a>
                            </li>
                            <li>
                                <span className="footer-subtitle">
                                    Telefon:
                                </span>{' '}
                                <br />
                                <a href="tel:+40721241571">
                                    (+40) 741 577 775
                                </a>{' '}
                            </li>

                            <li>
                                <span className="footer-subtitle">Email:</span>{' '}
                                <br />
                                <a href="mailto:office@ecomondo.ro">
                                    office@ecomondo.ro
                                </a>
                            </li>
                            <li>
                                <a href="mailto:adriandorin.leu@gmail.com">
                                    adriandorin.leu@gmail.com
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-4">
                        <h5 style={{ color: '#0acf83' }}>Urmăreste-ne</h5>
                        <ul className="list-unstyled social-footer">
                            <li>
                                <a href="#">
                                    <i className="fab fa-facebook fa-lg"></i>{' '}
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="fab fa-twitter fa-lg"></i>{' '}
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="fab fa-instagram fa-lg"></i>{' '}
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="fab fa-youtube fa-lg"></i>{' '}
                                    LinkedIn
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
