import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { Routes, Route } from 'react-router-dom';

import {
    Homepage,
    Legislation,
    Contact,
    EmergencySituations,
    Offer,
    NotFound,
} from './pages';

import { NavbarMain, Footer } from './components';

function App() {
    return (
        <div
            className="d-flex flex-column min-vh-100
        ">
            <NavbarMain />
            <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="legislatie" element={<Legislation />} />
                <Route path="oferta" element={<Offer />} />
                <Route
                    path="situatii_de_urgenta"
                    element={<EmergencySituations />}
                />
                <Route path="contact" element={<Contact />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;
