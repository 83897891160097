import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import './ModalMain.scss';
import { ReactComponent as CheckIcon } from '../assets/solidCircle.svg';

const ModalMain = ({ show, onHide, title, description, bullets }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    // Set isSmallScreen state based on window width
    const handleWindowSizeChange = () => {
        if (window.innerWidth < 576) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }
    };

    // Add event listener to window to detect screen size changes
    window.addEventListener('resize', handleWindowSizeChange);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            dialogClassName={isSmallScreen ? 'modal-dialog-centered' : ''}
            className={isSmallScreen ? 'main-modal-sm' : 'main-modal'}>
            <Modal.Header className="d-flex justify-content-end">
                <Button variant="link" onClick={onHide}>
                    <FaTimes />
                </Button>
            </Modal.Header>
            <Modal.Body style={{ margin: '1rem' }}>
                <h4 className="text-right font-weight-bold">{title}</h4>
                <p className="text-right">{description}</p>

                {bullets.map((bullet, index) => (
                    <div
                        className="d-flex align-items-start"
                        style={{ marginBottom: '0.5rem' }}>
                        <div className="">
                            <CheckIcon
                                style={{
                                    height: '1rem',
                                    width: '1rem',
                                    marginRight: '0.5rem',
                                }}
                            />
                        </div>
                        <div>{bullet}</div>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    );
};

export default ModalMain;
