import checkedCircle from '../../assets/checkedCircle.svg';
import checkedDocument from '../../assets/checkedDocument.svg';
import consult from '../../assets/consult.svg';
import emergency from '../../assets/emergency.svg';
import instruction from '../../assets/instruction.svg';
import nounVest from '../../assets/nounVest.svg';
import riskEvaluation from '../../assets/riskEvaluation.svg';

const cardData = [
    {
        title: 'CONSULTANTA IN DOMENIUL SSM',
        description:
            'ECOMONDO SSM ofera in domeniul securitatii si sanatatii in munca consultanta de specialitate pe probleme de securitatea si sanatatea lucratorilor in conformitate cu normele metodologice de aplicare a prevederilor Legii securitatii si sanatatii in munca 319/2006',
        bullets: [
            'Identificarea pericolelor si evaluarea riscurilor pentru fiecare loc de munca',
            'Elaborarea si actualizarea planului de prevenire si protectie',
            'Elaborarea de instrucţiuni proprii pentru completarea şi/sau aplicarea reglementărilor de securitate şi sănătate în munca, ţinând seama de particularităţile activităţilor şi ale unităţii, precum şi ale locurilor de munca/ posturilor de lucru',
            'Intocmirea unui necesar de documentaţii cu caracter tehnic de informare şi instruire a lucrătorilor în domeniul securităţii şi sănătăţii în munca',
            'Elaborarea programului de instruire-testare la nivelul intreprinderii si/sau unitatii',
            'Evaluarea riscurilor si documentatia pentru securitatea si sanatatea in munca sunt obligatoriu sa fie realizate si sa se afle in posesia angajatorului (conform Art. 12 Legea 319/2006)',
        ],
        svg: nounVest,
    },
    {
        title: 'CONSULTANTA IN DOMENIUL SITUATIILOR DE URGENTA',
        description:
            'ECOMONDO SSM ofera in domeniul Situatiilor de urgenta consultanta in calitate de Cadru Tehnic cu Atributii in Apararea Impotriva Incendiilor - AIl si Protectie Civila - PC',
        bullets: [
            'Intocmeste actele de autoritate emise de administratorul societatii(conducatorul institutiei) privind apararea impotriva incendiilor',
            'Intocmeste planurile de protectie specifice situatiilor de urgenta',
            'Efectueaza instructajul introductiv general in domeniul situatiilor de urgenta pentru angajatii beneficiarului la sediul societatii(institutiei), respectiv la cabinetul de instruire in domeniul situatiilor de urgenta al S.C. Ecomondo SSM',
            'Intocmeste instructiunile de aparare impotriva incendiilor',
            'Intocmeste tematica anuala si graficul de instruire –testare a lucratorilor in domeniul situatiilor de urgenta',
            'Asigura consultanta, informarea si indrumarea privind asigurarea cerintelor minime de semnalizare in domeniul situatiilor de urgenta conform H.G. 971/2006 si dotarea cu mijloace tehnice de stingere a incendiilor',
        ],
        svg: emergency,
    },
    {
        title: 'CONSULTANTA IN DOMENIUL RESURSELOR UMANE',
        description:
            'ECOMONDO SSM ofera in domeniul Resurselor Umane consultanta privitor la intocmirea dosarului cu urmatoarele documente',
        bullets: [
            'Regulamentul de Ordine Interioara (inclusiv obligatiile de a cunoaste si respecta deciziile de securitatea si sanatatea muncii)',
            'Fisa postului (inclusiv modul de insusire si respectare a masurilor pentru securitatea si sanatatea muncii)',
            'Contractul Colectiv de Munca',
        ],
        svg: consult,
    },
    {
        title: 'Intocmire documentatie in securitatea in munca',
        description:
            'Intocmirea documentatiei in domeniul securitatii si sanatatii in munca este un serviciu oferit de ECOMONDO SSM prin care se elaboreaza si actualizeaza documentatia necesara pentru respectarea prevederilor legale privind securitatea si sanatatea in munca.',
        bullets: [
            'Identificarea pericolelor si evaluarea riscurilor pentru fiecare loc de munca',
            'Elaborarea si actualizarea planului de prevenire si protectie',
            'Intocmirea de instructiuni proprii pentru completarea si aplicarea reglementarilor de securitate si sanatate in munca.',
        ],
        svg: checkedDocument,
    },
    {
        title: 'Elaborarea tematicii pentru faze instruire',
        description:
            'Echipa noastra ofera servicii de consultanta in elaborarea tematicii pentru fazele de instruire din domeniul securitatii si sanatatii in munca. Astfel, ne asiguram ca toate activitatile de formare si informare a angajatilor sunt realizate in conformitate cu normele metodologice de aplicare a prevederilor Legii securitatii si sanatatii in munca 319/2006.',
        bullets: [
            'Identificarea nevoilor de formare si elaborarea unei tematici personalizate',
            'Adaptarea continutului instructiunilor de securitate si sanatate in munca la particularitatile activitatii si ale unitatii',
            'Asigurarea respectarii prevederilor legale si a standardelor de calitate in domeniul formarii si instruirii angajatilor',
        ],
        svg: instruction,
    },
    {
        title: 'Evaluarea factorilor de risc',
        description:
            'Evaluarea factorilor de risc este un proces de identificare si evaluare a riscurilor de accidentare sau imbolnavire profesionala in cadrul unei organizatii. Aceasta evaluare este necesara pentru a putea dezvolta si implementa masuri de prevenire si protectie a lucratorilor impotriva riscurilor identificate.',
        bullets: [
            'Identificarea si evaluarea tuturor riscurilor pentru sanatatea si securitatea lucratorilor, inclusiv riscuri fizice, chimice, biologice, psihologice si ergonomice.',
            'Elaborarea unui plan de actiune pentru reducerea si prevenirea riscurilor identificate.',
            'Asigurarea ca toate masurile necesare sunt implementate si monitorizate in mod regulat pentru a asigura conformitatea cu legislatia in domeniu si pentru a asigura un mediu de lucru sigur si sanatos.',
            'Identificarea factorilor de risc pentru fiecare loc de munca si pentru fiecare activitate desfasurata in cadrul unitatii.',
            'Evaluarea riscurilor la care sunt expusi lucratorii, inclusiv prin masurarea nivelurilor de zgomot, luminozitate, temperatura, umiditate sau alti parametri specifici locului de munca.',
        ],
        svg: riskEvaluation,
    },
    {
        title: 'Servicii complete de medicina muncii',
        description:
            'Serviciile complete de medicina muncii oferite de ECOMONDO includ evaluarea starii de sanatate a angajatilor, prevenirea imbolnavirilor si a accidentelor de munca, precum si implementarea masurilor de protectie necesare. Printre serviciile noastre se numara:',
        bullets: [
            'Examinarea medicala periodica a angajatilor in conformitate cu legislatia in vigoare',
            'Evaluarea starii de sanatate a angajatilor expusi la factori de risc profesionali',
            'Elaborarea programului de prevenire si control al imbolnavirilor profesionale',
            'Realizarea de investigatii medicale si analize de laborator specifice',
            'Consiliere si educatie privind sanatatea si siguranta in munca',
        ],
        svg: checkedCircle,
    },
    {
        title: 'Audit SSM',
        description:
            'Auditul SSM presupune o evaluare obiectiva si independenta a starii actuale de securitate si sanatate in munca a unei companii, cu scopul de a identifica problemele existente si de a propune masuri eficiente pentru imbunatatirea acestora.',
        bullets: [
            'Evaluarea starii actuale de securitate si sanatate in munca a companiei',
            'Identificarea problemelor si a riscurilor pentru lucratorii din companie',
            'Propunerea de solutii si masuri pentru imbunatatirea securitatii si sanatatii in munca',
            'Asistenta si consultanta in vederea implementarii masurilor propuse pentru imbunatatirea SSM.',
        ],
        svg: emergency,
    },
];

export default cardData;
