import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './NavbarMain.scss';
import Image from 'react-bootstrap/Image';
import logo from '../../assets/logo.svg';

function NavbarMain() {
    const [isSticky, setIsSticky] = useState(false);
    const currentPath = window.location.pathname;

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        if (scrollPosition >= 50) {
            setIsSticky(true);
        } else {
            setIsSticky(false);
        }
    };

    window.addEventListener('scroll', handleScroll);

    return (
        <Navbar
            bg="white"
            variant="white"
            expand="md" // add the expand prop here
            className={`${isSticky ? 'sticky' : ''} shadow-lg fw-bold`}>
            <Container>
                <Navbar.Brand href="/" className="text-dark fw-bolder ">
                    <Image
                        src={logo}
                        alt="Logo"
                        width="30"
                        height="30"
                        className="mr-2"
                    />
                    <span className="mx-2">Ecomondo</span>
                </Navbar.Brand>{' '}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                    id="basic-navbar-nav"
                    className="justify-content-end">
                    {' '}
                    <Nav className="text-white">
                        <Nav.Link
                            href="/"
                            className={`${
                                currentPath === '/'
                                    ? 'active custom-active'
                                    : 'inactive'
                            }`}>
                            Prima pagina
                        </Nav.Link>
                        <Nav.Link
                            href="/legislatie"
                            className={`${
                                currentPath === '/legislatie'
                                    ? 'active custom-active'
                                    : 'inactive'
                            }`}>
                            Legislatie
                        </Nav.Link>
                        <Nav.Link
                            href="/oferta"
                            className={`${
                                currentPath === '/oferta'
                                    ? 'active custom-active'
                                    : 'inactive'
                            }`}>
                            Oferta SSM
                        </Nav.Link>
                        <Nav.Link
                            href="/situatii_de_urgenta"
                            className={`${
                                currentPath === '/situatii_de_urgenta'
                                    ? 'active custom-active'
                                    : 'inactive'
                            }`}>
                            Situatii de urgenta
                        </Nav.Link>
                        <Nav.Link
                            href="/contact"
                            className={`${
                                currentPath === '/contact'
                                    ? 'active custom-active'
                                    : 'inactive'
                            }`}>
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarMain;
