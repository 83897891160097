import { Link } from 'react-scroll';
import plasterImage from '../../assets/mainPlaster.svg';
import arrowDown from '../../assets/vector.svg';
import { Card, Col, Container, Row } from 'react-bootstrap';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState, useEffect } from 'react';
import ModalMain from '../../components/ModalMain';
import cardData from './cardData.jsx';
import './Homepage.scss';

function Homepage() {
    const [selectedCard, setSelectedCard] = useState(null);
    const section1Ref = useRef(null);
    const section2Ref = useRef(null);
    const section3Ref = useRef(null);

    const handleOpenModal = (index) => {
        setSelectedCard(index);
    };

    const handleCloseModal = () => {
        setSelectedCard(null);
    };

    const handleScrollDown = () => {
        section2Ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <section
                className="container-fluid vh-100"
                id="section1"
                ref={section1Ref}>
                <Container className="h-100">
                    <Row className="align-items-center h-100">
                        <Col lg={6} className="order-lg-2 p-5 text-center">
                            <img
                                src={plasterImage}
                                className="img-fluid"
                                alt="Plaster"
                            />
                        </Col>
                        <Col lg={6} className="order-lg-1 p-5">
                            <h2 className="text-black fw-bolder">
                                Bine ati venit la ECOMONDO SSM!
                            </h2>
                            <p>
                                Expertii dumneavoastra in securitatea si
                                sanatatea in munca! Suntem aici pentru a va
                                oferi consultanta de specialitate si solutii
                                personalizate care sa va asigure respectarea
                                normelor si prevederilor legale in domeniu.
                            </p>
                            <p>
                                Fie ca sunteti angajator sau angajat, suntem
                                pregatiti sa va ajutam sa asigurati un mediu de
                                lucru sigur si sanatos pentru toti cei
                                implicati.
                            </p>
                            <p className="quote">
                                "Nu exista nicio prioritate mai importanta decat
                                siguranta." - Confucius
                            </p>
                        </Col>
                        <Col
                            lg={12}
                            className="col-12 d-flex bttom-0 postion-absolute flex-column justify-content-center align-items-center order-lg-3 d-none d-lg-flex"
                            style={{ height: '20%' }}>
                            <Link
                                to="offered-services"
                                spy={true}
                                smooth={true}
                                duration={500}
                                onClick={handleScrollDown}
                                style={{ cursor: 'pointer' }}
                                className="arrow-icon">
                                <img
                                    src={arrowDown}
                                    style={{ height: '2rem', width: '4rem' }}
                                />
                            </Link>
                            <p className="fw-bolder">Scroll down</p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section
                className="container-fluid mb-5"
                style={{ paddingTop: '10rem' }}
                id="section2"
                ref={section2Ref}>
                <Container className="h-100">
                    <h2 className="text-center fw-bolder">Servicii oferite</h2>
                    <hr
                        className="mx-auto"
                        style={{
                            height: '7px',
                            width: '10%',
                            border: 'none',
                            backgroundColor: '#000',
                        }}
                    />
                    <Row className="mt-5">
                        {cardData.map((card, index) => (
                            <Col
                                key={index}
                                sm={6}
                                md={4}
                                lg={3}
                                className="mb-4">
                                <div className="card pt-5">
                                    <div className="d-flex justify-content-center align-items-center p-3">
                                        <Card.Img
                                            variant="top"
                                            src={cardData[index].svg}
                                            style={{
                                                height: '4rem',
                                                width: '4rem',
                                            }}
                                        />
                                    </div>
                                    <div className="d-flex flex-column justify-content-between p-4">
                                        <div className="text-center">
                                            <span className="fw-bolder fs-8">
                                                {card.title}
                                            </span>
                                        </div>
                                        <div className="text-center fs-7 fw-bolder mt-3">
                                            <span
                                                onClick={() =>
                                                    handleOpenModal(index)
                                                }
                                                role="button">
                                                Show More{' '}
                                                <FontAwesomeIcon
                                                    icon={faChevronRight}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                {selectedCard === index && (
                                    <ModalMain
                                        show={selectedCard === index}
                                        onHide={handleCloseModal}
                                        title={card.title}
                                        description={card.description}
                                        bullets={card.bullets}
                                    />
                                )}
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>
            <section
                className="container-fluid mb-5 mt-5"
                id="section3"
                ref={section3Ref}>
                <Container>
                    <h2 className="text-center fw-bolder">Relatii de munca</h2>
                    <hr
                        className="mx-auto"
                        style={{
                            height: '7px',
                            width: '10%',
                            border: 'none',
                            backgroundColor: '#000',
                        }}
                    />
                    <Row className="mt-5">
                        <Col>
                            <p>
                                SC ECOMONDO SSM ofera consultanta de
                                specialitate in domeniul Resurselor Umane, avand
                                o experienta vasta in intocmirea si actualizarea
                                documentatiei necesare unei bune gestionari a
                                personalului. Printre documentele oferite se
                                numara:
                            </p>
                            <ul>
                                <li>
                                    Regulamentul de Ordine Interioara, care
                                    include obligatiile angajatilor de a
                                    cunoaste si respecta deciziile de
                                    securitatea si sanatatea muncii, precum si
                                    reguli specifice de comportament si
                                    organizare in cadrul firmei;
                                </li>
                                <li>
                                    Fisa postului, care descrie atributiile si
                                    responsabilitatile specifice ale fiecarui
                                    post din cadrul organizatiei, precum si
                                    masurile necesare pentru securitatea si
                                    sanatatea muncii aferente acestuia;
                                </li>
                                <li>
                                    Contractul Colectiv de Munca, care
                                    reprezinta documentul legal prin care se
                                    stabilesc drepturile si obligatiile
                                    angajatorului si ale angajatilor, inclusiv
                                    cele referitoare la conditiile de munca si
                                    protectia muncii.
                                </li>
                            </ul>
                            <p>
                                Aceste documente sunt esentiale pentru o buna
                                organizare a activitatii firmei si pentru
                                respectarea normelor legale in vigoare in
                                domeniul resurselor umane. Echipa noastra de
                                specialisti va poate oferi consultanta si
                                asistenta pentru intocmirea si actualizarea
                                acestor documente, astfel incat sa va asigurati
                                ca gestionati corect si eficient personalul
                                companiei dumneavoastra.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Homepage;
