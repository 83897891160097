import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Legislation.scss';
function Legislation() {
    const documentList = [
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
        { title: 'Hotarare de Guvern nr. 300 / 02.03.2006' },
    ];

    const rows = [];
    for (let i = 0; i < documentList.length; i += 6) {
        const rowDocuments = documentList.slice(i, i + 6);
        rows.push(rowDocuments);
    }

    return (
        <>
            <section className="container-fluid mt-5 mb-5">
                <Container>
                    <Row>
                        <Col md={6} lg={12}>
                            <h2
                                style={{
                                    display: 'inline-block',
                                    borderBottom: '7px solid black',
                                    fontWeight: 'bold',
                                }}
                                className="mb-3">
                                Legislatie
                            </h2>

                            <p>
                                Oferta noastra pentru servicii de legislatie
                                consta in consultanta de specialitate in
                                domeniul legislatiei muncii si al securitatii si
                                sanatatii in munca, oferind solutii si
                                recomandari personalizate pentru fiecare client
                                in parte. Iata cateva puncte-cheie ale acestei
                                oferte:.
                            </p>
                            <ul>
                                <li className="mb-3">
                                    Consultanță privind respectarea legislației
                                    în vigoare în domeniul SSM, protecției
                                    mediului, protecției muncii și protecției
                                    consumatorului
                                </li>
                                <li className="mb-3">
                                    Asistență în elaborarea documentației
                                    necesare conform legislației în vigoare
                                </li>
                                <li>
                                    Analizarea și interpretarea legislației
                                    relevante pentru activitatea clientului
                                </li>
                                <li className="mb-3">
                                    Actualizarea clientului cu privire la orice
                                    modificări legislative relevante pentru
                                    domeniul său de activitate
                                </li>
                                <li className="mb-3">
                                    Rezolvarea de situații conflictuale între
                                    client și autorități
                                </li>
                                <li className="mb-3">
                                    Suport și reprezentare în cazul unor
                                    controale ale autorităților și în cadrul
                                    procedurilor de obținere a avizelor,
                                    autorizațiilor sau certificatelor necesare
                                    activității clientului
                                </li>
                                <li className="mb-3">
                                    Identificarea și eliminarea
                                    neconformităților legale din activitatea
                                    clientului
                                </li>
                                <li className="mb-3">
                                    Asistență în ceea ce privește implementarea
                                    și respectarea standardelor de mediu,
                                    sănătate și securitate în muncă, protecție a
                                    consumatorului etc.
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Legislation;
